<template>
    <div class="wrapper__sidebar">
        <div class="sidebar__top">
            <div class="header__sidebar" @click="toggleSidebarOpen">
                <a>
                    <span>

                    </span>
                </a>
            </div>
            <div class="sidebar__logo">
                <a href="#">
                    <img src="https://stylopay-sandbox-us-east-1-dev-dump.s3.amazonaws.com/yourlogo.png" alt="">
                </a>
            </div>
        </div>
        <div class="sidebar__menu">
            <ul class="sidebar__list ">
                <li class="sidebar__list-item svg__BW" :class="currentPath == '/accountDashboard' ? 'active' : ''"
                    @click="toPage('/accountDashboard')" data-title="Account" id="globalAccountSideBar">
                    <a href="javascript:void(0)">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                <g>
                                    <g>
                                        <g>
                                            <path class="svg__gray" fill="#3246d3"
                                                d="M13 26c7.18 0 13-5.82 13-13S20.18 0 13 0 0 5.82 0 13s5.82 13 13 13z">
                                            </path>
                                        </g>
                                        <g>
                                            <path class="svg__black" fill="#ffa720"
                                                d="M5.648 8.718l1.084.138c.279.04.554-.09.7-.331a1.983 1.983 0 0 1 1.966-.969l.474.06c.243.03.484-.069.634-.262l.417-.54c.306-.394.32-.94.034-1.349l-.9-1.296A3.916 3.916 0 0 0 8.824 3.03a4.44 4.44 0 0 1-.423-.28L6.816 1.56a13.05 13.05 0 0 0-3.007 2.243 12.864 12.864 0 0 0-3.719 7.65 2.376 2.376 0 0 1 2.492 1.136c.09.155.202.296.333.418l.943.886c.136.128.302.22.483.27l.11.03a2.19 2.19 0 0 1 1.529 1.52l.506 1.82c.05.186.137.36.255.513l1.532 2.005c.218.286.363.62.422.974l.417 2.494a.1.1 0 0 0 .03.061c.024.02.058.025.087.013.148-.062.25-.2.265-.36.055-.565.28-1.1.647-1.533l.445-.532a.655.655 0 0 0 .123-.227l.456-1.478c.15-.49.45-.92.859-1.232l1.357-1.04a.215.215 0 0 0 .085-.131.188.188 0 0 0-.043-.157l-.904-1.06a3.364 3.364 0 0 1-.168-.215l-.69-.96c-.35-.487-.882-.768-1.423-.755a.617.617 0 0 1-.333-.087l-1.412-.846a1.104 1.104 0 0 0-1.082-.026l-.744.398c-.8.424-1.794.164-2.282-.6l-.722-1.138a1.835 1.835 0 0 1 .03-2.014 2.04 2.04 0 0 1 1.955-.882z">
                                            </path>
                                        </g>
                                        <g>
                                            <path fill="#fff"
                                                d="M21.479 3.14l-.358.499a16.511 16.511 0 0 0-2.115 2.855l-.49.835a.381.381 0 0 0 .05.432.884.884 0 0 0 .557.295l.237.029a2.62 2.62 0 0 1 2.186 1.835c.291.959-.004 2-.755 2.665l-2.331 2.06a.79.79 0 0 0-.26.6v.547a.494.494 0 0 0 .224.428 2.464 2.464 0 0 1 1.12 1.847l.055.539c.02.184.178.321.363.315h1.369a1.477 1.477 0 0 0 1.363-.876l.082-.174a2.499 2.499 0 0 1 2.188-1.497l.322-.015a1.51 1.51 0 0 0 .078-.006l.197-.02a13.02 13.02 0 0 0 .44-3.358 12.875 12.875 0 0 0-3.808-9.173c-.231-.23-.47-.451-.714-.662z">
                                            </path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                        Global Accounts
                        <br>
                        Wallet
                    </a>
                </li>
                <li class="sidebar__list-item svg__BW" :class="currentPath == '/manageCards' ? 'active' : ''"
                    @click="toPage('/manageCards')" data-title="My Cards" id="cardsSideBar">
                    <a href="javascript:void(0)">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="20" viewBox="0 0 26 20">
                                <g>
                                    <g>
                                        <g>
                                            <path class="svg__black" fill="#3246d3"
                                                d="M22.662 3.967v.576H5.582a2.127 2.127 0 0 0-2.085 2.135v8.994H2.085A2.093 2.093 0 0 1 0 13.572V2.12C0 .96.933.02 2.085.02h18.492c1.152 0 2.085.94 2.085 2.1z">
                                            </path>
                                        </g>
                                        <g>
                                            <path class="svg__gray" fill="#ffa720"
                                                d="M25.962 11.09v6.81c0 1.16-.933 2.1-2.085 2.1H5.385A2.093 2.093 0 0 1 3.3 17.9v-6.81z">
                                            </path>
                                        </g>
                                        <g>
                                            <path class="svg__black" fill="#3246d3"
                                                d="M25.962 8.04v3.05H3.3V8.04h.168z">
                                            </path>
                                        </g>
                                        <g>
                                            <path class="svg__gray" fill="#ffa720"
                                                d="M25.962 6.44v1.593H3.3V6.44c0-1.16.933-2.1 2.085-2.1h18.492c1.152 0 2.085.94 2.085 2.1z">
                                            </path>
                                        </g>
                                        <g>
                                            <path fill="#fff"
                                                d="M21.717 13.963a1.726 1.726 0 0 1 1.459 1.952 1.721 1.721 0 0 1-1.459 1.47 1.61 1.61 0 0 1-1.311-.644 1.61 1.61 0 0 1-1.311.644 1.72 1.72 0 0 1-1.764-1.677 1.722 1.722 0 0 1 1.664-1.777 1.71 1.71 0 0 1 1.41.676c.321-.4.802-.637 1.312-.644z">
                                            </path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                        Manage Cards
                    </a>
                    <ul>
                        <!-- <li>
										<a href="/manageCards">Manage Cards</a>
									</li> -->
                        <li class="sidebar__list-item svg__BW" :class="currentPath == '/getCard' ? 'active' : ''"
                            @click.stop="toPage('/getCard')" style="margin-left:45px;" data-title="getCard"
                            id="getCard">
                            <a href="javascript:void(0)">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" viewBox="0 0 22 22">
                                        <path class="svg__gray" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="M18 15v3m0 3v-3m0 0h-3m3 0h3">
                                        </path>
                                        <path class="svg__black" fill="#3246d3" fill-rule="evenodd"
                                            d="M5 4a3 3 0 0 0-3 3v1h20V7a3 3 0 0 0-3-3H5zM2 17v-7h20v3.528A6 6 0 0 0 12.341 20H5a3 3 0 0 1-3-3zm4-4a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1z"
                                            clip-rule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                                Get a Card</a>
                        </li>
                    </ul>
                </li>
				<!-- <li class="sidebar__list-item svg__BW" :class="currentPath == '/currencyEncryption' ? 'active' : ''"
				    @click="toPage('/currencyEncryption')" data-title="Transfers" id="currencyEncryption">
				    <a href="javascript:void(0)">
				        <span>
				            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="22" viewBox="0 0 28 22">
				                <g>
				                    <g>
				                        <g>
				                            <path class="svg__black" fill="#3246d3"
				                                d="M19 22l.217-.005A5 5 0 0 0 24 17v-4l-.007-.117A1 1 0 0 0 22 13v4l-.005.176A3 3 0 0 1 19 20H9l-.176-.005A3 3 0 0 1 6 17v-1l-.007-.117A1 1 0 0 0 4 16v1l.005.217A5 5 0 0 0 9 22zM9 0l-.217.005A5 5 0 0 0 4 5v4l.007.117A1 1 0 0 0 6 9V5l.005-.176A3 3 0 0 1 9 2h10l.176.005A3 3 0 0 1 22 5v1l.007.117A1 1 0 0 0 24 6V5l-.005-.217A5 5 0 0 0 19 0z">
				                            </path>
				                        </g>
				                        <g>
				                            <path class="svg__gray" fill="#ffa720"
				                                d="M1.414 5.5a1 1 0 0 0-.707 1.707l3.586 3.586a1 1 0 0 0 1.414 0l3.586-3.586A1 1 0 0 0 8.586 5.5z">
				                            </path>
				                        </g>
				                        <g>
				                            <path class="svg__gray" fill="#ffa720"
				                                d="M19.414 15.586a1 1 0 0 1-.707-1.707l3.586-3.586a1 1 0 0 1 1.414 0l3.586 3.586a1 1 0 0 1-.707 1.707z">
				                            </path>
				                        </g>
				                    </g>
				                </g>
				            </svg>
				        </span>
				        Currency Encryption
				    </a>
				</li> -->
                <li class="sidebar__list-item svg__BW" :class="currentPath == '/transferP2Pmm' ? 'active' : ''"
                    @click="toPage('/transferP2Pmm')" data-title="Transfers" id="transfersSideBar">
                    <a href="javascript:void(0)">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="22" viewBox="0 0 28 22">
                                <g>
                                    <g>
                                        <g>
                                            <path class="svg__black" fill="#3246d3"
                                                d="M19 22l.217-.005A5 5 0 0 0 24 17v-4l-.007-.117A1 1 0 0 0 22 13v4l-.005.176A3 3 0 0 1 19 20H9l-.176-.005A3 3 0 0 1 6 17v-1l-.007-.117A1 1 0 0 0 4 16v1l.005.217A5 5 0 0 0 9 22zM9 0l-.217.005A5 5 0 0 0 4 5v4l.007.117A1 1 0 0 0 6 9V5l.005-.176A3 3 0 0 1 9 2h10l.176.005A3 3 0 0 1 22 5v1l.007.117A1 1 0 0 0 24 6V5l-.005-.217A5 5 0 0 0 19 0z">
                                            </path>
                                        </g>
                                        <g>
                                            <path class="svg__gray" fill="#ffa720"
                                                d="M1.414 5.5a1 1 0 0 0-.707 1.707l3.586 3.586a1 1 0 0 0 1.414 0l3.586-3.586A1 1 0 0 0 8.586 5.5z">
                                            </path>
                                        </g>
                                        <g>
                                            <path class="svg__gray" fill="#ffa720"
                                                d="M19.414 15.586a1 1 0 0 1-.707-1.707l3.586-3.586a1 1 0 0 1 1.414 0l3.586 3.586a1 1 0 0 1-.707 1.707z">
                                            </path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                        Transfers
                    </a>
                </li>
                <li class="sidebar__list-item svg__BW" :class="currentPath == '/transactions' ? 'active' : ''"
                    @click="toPage('/transactions')" data-title="Transactions" id="transactionsSideBar">
                    <a href="javascript:void(0)">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="26" viewBox="0 0 22 26">
                                <g>
                                    <g>
                                        <g>
                                            <path class="svg__black" fill="#3246d3"
                                                d="M0 2a2 2 0 0 1 2-2h14l6 8v16a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2z">
                                            </path>
                                        </g>
                                        <g>
                                            <path class="svg__gray" fill="#ffa720"
                                                d="M11.127 22.082C7.2 22.082 4 18.918 4 15.042 4 11.161 7.202 8 11.127 8c3.924 0 7.126 3.164 7.126 7.041 0 3.877-3.2 7.041-7.126 7.041z">
                                            </path>
                                        </g>
                                        <g>
                                            <path fill="#fff"
                                                d="M12.77 17.751c-.28 0-.56-.105-.774-.317L10.35 15.81a1.074 1.074 0 0 1-.321-.765v-3.25c0-.598.492-1.084 1.097-1.084a1.09 1.09 0 0 1 1.097 1.084v2.802l1.324 1.308a1.075 1.075 0 0 1 0 1.532c-.217.21-.497.315-.778.315z">
                                            </path>
                                        </g>
                                        <g>
                                            <path fill="#acaec8" d="M16 0l6 8h-4a2 2 0 0 1-2-2z">

                                            </path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                        Transactions
                    </a>
                </li>
                <li class="sidebar__list-item svg__BW" :class="currentPath == '/recipients' ? 'active' : ''"
                    @click="toPage('/recipients')" data-title="Recipients" id="recipientsSideBar">
                    <a href="javascript:void(0)">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 25 20">
                                <g>
                                    <g>
                                        <g>
                                            <path fill="#1a1b26"
                                                d="M17.306 8.519c-2.325 0-4.216-1.911-4.216-4.26C13.09 1.911 14.981 0 17.306 0c2.324 0 4.215 1.91 4.215 4.26 0 2.348-1.891 4.259-4.215 4.259z">
                                            </path>
                                        </g>
                                        <g>
                                            <path fill="#acaec8"
                                                d="M17.306 10.74a6.36 6.36 0 0 1-4.503-1.87 7.743 7.743 0 0 0-1.623 1.594l.604.535a8.559 8.559 0 0 1 2.882 6.407V20h8.337a2 2 0 0 0 2-2v-2.815a7.797 7.797 0 0 0-3.2-6.308 6.36 6.36 0 0 1-4.497 1.864z">
                                            </path>
                                        </g>
                                        <g>
                                            <path fill="#1a1b26"
                                                d="M10.332 12.67a5.637 5.637 0 0 1-4.1 1.773 5.637 5.637 0 0 1-4.1-1.773A6.31 6.31 0 0 0 0 17.406v1.592a1 1 0 0 0 1 1h11.463v-2.592a6.31 6.31 0 0 0-2.131-4.736z">
                                            </path>
                                        </g>
                                        <g>
                                            <path fill="#acaec8"
                                                d="M6.232 12.227c-1.92 0-3.482-1.578-3.482-3.518 0-1.94 1.562-3.519 3.482-3.519 1.92 0 3.483 1.578 3.483 3.519 0 1.94-1.562 3.518-3.483 3.518z">
                                            </path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                        Recipients
                    </a>
                </li>
                <li class="sidebar__list-item" :class="currentPath == '/feemodule' ? 'active' : ''"
                    @click="toPage('/feemodule')" data-title="Transactions" id="feetableSideBar">
                    <a href="javascript:void(0)">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="26" viewBox="0 0 22 26">
                                <g>
                                    <g>
                                        <g>
                                            <path fill="#1a1b26"
                                                d="M0 2a2 2 0 0 1 2-2h12l8 10v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2z">
                                            </path>
                                        </g>
                                        <g>
                                            <path fill="#acaec8" d="M14 0l8 10h-6a2 2 0 0 1-2-2z">

                                            </path>
                                        </g>
                                        <g>
                                            <path fill="#fff" d="M4 13a1 1 0 0 1 1-1h3a1 1 0 0 1 0 2H5a1 1 0 0 1-1-1z">
                                            </path>
                                        </g>
                                        <g>
                                            <path fill="#fff" d="M4 19a1 1 0 0 1 1-1h8a1 1 0 0 1 0 2H5a1 1 0 0 1-1-1z">
                                            </path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                        Fee Table
                    </a>
                </li>
                <li class="sidebar__list-item svg__BW" :class="currentPath == '/Setting' ? 'active' : ''"
                    @click="toPage('/Setting')" data-title="Settings" id="settingsSideBar">
                    <a href="javascript:void(0)">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                <g>
                                    <g>
                                        <g>
                                            <path fill="#1a1b26"
                                                d="M13.816 0a2.396 2.396 0 0 1 2.393 2.393v.553c.561.179 1.107.405 1.63.676l.392-.391a2.394 2.394 0 0 1 3.385 0l1.153 1.153c.922.921.948 2.438 0 3.386l-.391.39c.27.524.497 1.07.676 1.63h.552A2.396 2.396 0 0 1 26 12.185v1.632a2.396 2.396 0 0 1-2.394 2.393h-.552c-.179.562-.405 1.107-.676 1.63l.391.392a2.394 2.394 0 0 1 0 3.385l-1.154 1.153a2.393 2.393 0 0 1-3.385 0l-.39-.391c-.524.271-1.07.497-1.63.677v.552A2.396 2.396 0 0 1 13.815 26h-1.632a2.396 2.396 0 0 1-2.393-2.393v-.553a10.496 10.496 0 0 1-1.63-.676l-.392.391a2.394 2.394 0 0 1-3.385 0l-1.153-1.153a2.393 2.393 0 0 1 0-3.386l.391-.39a10.498 10.498 0 0 1-.677-1.63h-.552A2.396 2.396 0 0 1 0 13.815v-1.632a2.396 2.396 0 0 1 2.394-2.393h.552c.179-.562.405-1.107.676-1.63l-.391-.391a2.394 2.394 0 0 1 0-3.386l1.154-1.153a2.393 2.393 0 0 1 3.385 0l.39.391a10.506 10.506 0 0 1 1.63-.677v-.552A2.396 2.396 0 0 1 12.185 0z">
                                            </path>
                                        </g>
                                        <g>
                                            <path fill="#acaec8" d="M13 20a7 7 0 1 0 0-14 7 7 0 0 0 0 14z">

                                            </path>
                                        </g>
                                        <g>
                                            <path fill="#fff" d="M13 15a2 2 0 1 0 0-4 2 2 0 0 0 0 4z">

                                            </path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                        Settings
                    </a>
                </li>
            </ul>
        </div>
        <div class="sidebar__text"></div>
    </div>
</template>

<script>
export default {
    name: "Sidebar",
    props: {
        // 检测类型 + 其他验证
        // numberProp: {
        // 	type: Number,
        // 	default: 0,
        // 	validator: function(value) {
        // 		return value >= 0
        // 	}
        // },
        // stringProp: {
        // 	type: String,
        // 	default: '',
        // 	required: false,
        // },
        // objectProp: {
        // 	type: Object,
        // 	required: false,
        // 	default: function() {
        // 		return {};
        // 	}
        // },
        // arrayProp: {
        // 	type: Array,
        // 	required: false,
        // 	default: function() {
        // 		return [];
        // 	}
        // },
    },
    watch: {
        // 监听路由对象中的 `$route` 属性
        '$route'(to, from) {
            // 当路由发生变化时执行的代码
            console.log('Route changed from', from.path, 'to', to.path);
            this.currentPath = to.path
            // 你可以在这里根据路由做相应的处理
        }
    },
    computed: {
        // currentPath: {
        //     get() {
        //         console.log("this.$route.path: " + this.$route.path)
        //         return this.$route.path; // 获取当前路由路径
        //     }
        // }
    },
    // watch:{
    // 	propName:{
    // 		handler(newVal, oldVal){

    // 		}
    // 	}
    // },
    data() {
        return {
            currentPath: ''
        };
    },
    beforeCreate() { },
    created() { },
    beforeMount() { },
    //挂载到实例上去之后调用。
    //注意：此处并不能确定子组件被全部挂载，如果需要子组件完全挂载之后在执行操作可以使用$nextTick
    mounted() {
        this.currentPath = this.$route.path
        // console.log(" this.currentPath: ", this.currentPath)
    },
    //适用范围：仅H5平台支持
    beforeUpdate() { },
    //适用范围：仅H5平台支持
    updated() { },
    beforeDestroy() { },
    destroyed() { },
    methods: {
        toPage(path) {
            console.log(path)
            this.$router.push(path)
        },
        toggleSidebarOpen() {
            console.log('toggleSidebarOpen')
            this.$emit('toggleSidebarOpen')
        }
    }
}
</script>

<style lang="scss">
#getCard.active a {
    color: #000 !important;
    /* Change to black color for the active page */
}

.sidebar__list-item:not(.active) .svg__gray {
    stroke: #808080;
    /* Change this color to your desired color */
}

.sidebar__list-item.active a {
    stroke: #ffa720;
    /* Change this color to your desired color */
}

.active {
    pointer-events: auto;
}
</style>