<template>
	<div class="dashboard accountSetupPage">
		<main>
			<section class="main">
				<div class="el el-1">
					<img :src="require('@/assets/img/dashboardPages/account-el1.png')" alt="">
				</div>
				<div class="wrapper">
					<div class="el el-2">
						<img :src="require('@/assets/img/dashboardPages/account-el2.png')" alt="">
					</div>
					<div class="el el-3">
						<img :src="require('@/assets/img/dashboardPages/account-el3.png')" alt="">
					</div>
					<div class="el el-4">
						<span class="el-shadow el"> <img
								:src="require('@/assets/img/dashboardPages/account-el4-shadow.png')" alt="">
						</span> <img :src="require('@/assets/img/dashboardPages/account-el4.png')" alt="">
					</div>
					<div class="top">
						<a href="#" class="logo"><img
								src="https://stylopay-sandbox-us-east-1-dev-dump.s3.amazonaws.com/logo.svg" alt=""></a>
						<a href="/login" class="logout"> <span class="icon"> <svg width="18" height="18"
									viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M6.6748 5.66999C6.9073 2.96999 8.2948 1.86749 11.3323 1.86749H11.4298C14.7823 1.86749 16.1248 3.20999 16.1248 6.56249V11.4525C16.1248 14.805 14.7823 16.1475 11.4298 16.1475H11.3323C8.3173 16.1475 6.9298 15.06 6.6823 12.405"
										stroke="white" stroke-width="1.125" stroke-linecap="round"
										stroke-linejoin="round"></path>
									<path d="M11.2498 9H2.71484" stroke="white" stroke-width="1.125"
										stroke-linecap="round" stroke-linejoin="round"></path>
									<path d="M4.3875 6.48743L1.875 8.99993L4.3875 11.5124" stroke="white"
										stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
								</svg>
							</span> Logout
						</a>
					</div>
				</div>
				<div class="swiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
					<div class="swiper-wrapper" id="swiper-wrapper-10cdef259516532d7" aria-live="polite">
						<div v-if="PersonalDetail" class="swiper-slide swiper-slide-active" role="group"
							aria-label="1 / 2">
							<h1 class="title">Let's setup your account IN THREE SIMPLE
								STEPS</h1>
							<h4 class="subtitle">
								First, a little bit about yourself. <br> Enter your
								personal details as per your id proof.
							</h4>
						</div>
						<div v-if="addressShow" class="swiper-slide swiper-slide-next" role="group" aria-label="2 / 2">
							<h2 class="title">Let's setup your account</h2>
							<h4 class="subtitle">
								Next, tell us where you are located. <br> Enter your
								address info as per your address proof.
							</h4>
						</div>
						<!-- <div class="swiper-slide">
							<h2 class="title">Let's setup your account</h2>
							<h4 class="subtitle">
								Next, tell us about your identity proof. <br>
							</h4>
						</div> -->
					</div>
				</div>

			</section>
			<section class="steps">
				<div class="wrapper">
					<form class="form">
						<div class="step active" data-step="1" v-if="PersonalDetail">
							<h2 class="title">Your Personal Details</h2>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label class="label"> Title * </label>
								</div>
								<el-select class="input" style="padding: 15px 25px;" v-model="title" clearable
									filterable placeholder="Select">
									<el-option v-for="item in options" :key="item.label" :label="item.label"
										:value="item.label">
										<p>{{ item.label }}</p>
									</el-option>
								</el-select>
							</div>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label for="text" class="label"> First Name * </label>
									<!-- <p class="description">35 characters max</p> -->
								</div>
								<input type="text" class="input" v-model="firstName" name="firstName" maxlength="35">
							</div>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label for="text" class="label"> Middle Name </label>
									<!-- <p class="description">35 characters max</p> -->
								</div>
								<input type="text" class="input" name="middleName" v-model="middleName" maxlength="35">
							</div>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label for="text" class="label"> Last Name * </label>
									<!-- <p class="description">35 characters max</p> -->
								</div>
								<input type="text" class="input" name="lastName" v-model="lastName" maxlength="35">
							</div>

							<div class="block-item default-input">
								<div class="default-input__top">
									<label class="label"> Gender * </label>
								</div>
								<el-select class="input" style="padding: 15px 25px;" v-model="gender" clearable
									filterable placeholder="Select">
									<el-option v-for="item in optionsGender" :key="item.label" :label="item.label"
										:value="item.label">
										<p>{{ item.label }}</p>
									</el-option>
								</el-select>
							</div>

							<div class="block-item default-input">
								<div class="default-input__top">
									<label class="label"> Date Of Birth * </label>
								</div>
								<div class="input" style="padding: 15px 25px;">
									<el-date-picker style="width: 100%;" value-format="YYYY-MM-DD" v-model="dateOfBirth"
										type="date" placeholder="Select date">
									</el-date-picker>
								</div>
							</div>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label class="label"> idType * </label>
								</div>
								<el-select class="input" style="padding: 15px 25px;" v-model="idType" clearable
									filterable placeholder="Select">
									<el-option v-for="item in idTypeList" :key="item.label" :label="item.label"
										:value="item.label">
										<p>{{ item.label }}</p>
									</el-option>
								</el-select>
							</div>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label for="text" class="label"> idNumber * </label>
								</div>
								<input type="text" class="input" v-model="idNumber" name="idNumber" maxlength="35">
							</div>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label class="label"> Country Code * </label>
								</div>
								<el-select class="input" style="padding: 15px 25px;" v-model="phoneCountryCode"
									clearable filterable placeholder="Select">
									<template #prefix v-if="phoneCountryCode">
										<span class="flex">
											+
										</span>
									</template>
									<el-option v-for="item in countryCodeOptions" :key="item.phoneCode"
										:label="item.phoneCode" :value="item.phoneCode">
										<div style="display: flex;align-items: center;">
											<img style="width: 30px;height: 20px;margin-right: 5px;" :src="item.logo"
												alt="">
											<p>{{ item.name }} (+{{ item.phoneCode }})</p>
										</div>
									</el-option>
								</el-select>
							</div>
							<div id="errorShowPhone">
								<span id="errorPhone"
									style="color: red; padding-bottom: 10px; text-align: center; display: block;"></span>
							</div>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label class="label"> Phone Number * </label>
									<p class="description">Must be 8 to 11 digits</p>
								</div>
								<input type="number" class="input" v-model="phone" maxlength="11">
							</div>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label class="label"> Nationality * </label>
								</div>
								<el-select class="input" style="padding: 15px 25px;" v-model="nationality" clearable
									filterable placeholder="Select">
									<el-option v-for="item in optionsContry" :key="item.alpha2" :label="item.alpha2"
										:value="item.alpha2">
										<div style="display: flex;align-items: center;">
											<img style="width: 30px;height: 20px;margin-right: 5px;" :src="item.logo"
												alt="">
											<p>{{ item.name }} (+{{ item.phoneCode }})</p>
										</div>
									</el-option>
								</el-select>
								<!-- <select class="default-select" id="countryName" name="nationality">
                                </select> -->
							</div>
							<div class="block-button">
								<a @click="addressShowNext" class="default-button" id="continuebtm">Continue</a>
							</div>
						</div>
						<div class="step active" data-step="2" v-if="addressShow">
							<h2 class="title">Delivery Address</h2>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label class="label"> Country * </label>
								</div>
								<el-select class="input" style="padding: 15px 25px;" v-model="deliveryCountry" clearable
									filterable placeholder="Select">
									<el-option v-for="item in optionsContry" :key="item.alpha2" :label="item.alpha2"
										:value="item.alpha2">
										<div style="display: flex;align-items: center;">
											<img style="width: 30px;height: 20px;margin-right: 5px;" :src="item.logo"
												alt="">
											<p>{{ item.name }} (+{{ item.phoneCode }})</p>
										</div>
									</el-option>
								</el-select>
							</div>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label for="text" class="label"> Zip Code * </label>
								</div>
								<input type="text" class="input" maxlength="10" name="deliveryZipCode"
									v-model="deliveryZipCode">
							</div>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label for="text" class="label"> Address Line 1 * </label>
									<p class="description">50 characters max</p>
								</div>
								<input type="text" class="input" name="deliveryAddress1" v-model="deliveryAddress"
									maxlength="50">
							</div>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label for="text" class="label"> Address Line 2 * </label>
									<p class="description">50 characters max</p>
								</div>
								<input type="text" class="input" name="deliveryAddress2" v-model="deliveryAddressSecond"
									maxlength="50">
							</div>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label for="text" class="label"> City * </label>
								</div>
								<input type="text" class="input" name="deliveryCity" v-model="deliveryCity"
									maxlength="20">
							</div>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label for="text" class="label"> State * </label>
								</div>
								<input type="text" class="input" name="deliveryState" v-model="deliveryState"
									maxlength="20">
							</div>
							<div class="addChkBox"
								style="display: flex;align-items: center;color: rgba(237, 168, 88, 0.6);">
								<p>Billing Address same as Delivery Address</p>
								<el-checkbox @change="changeBox" v-model="isChecked"></el-checkbox>
							</div>
							<h2 class="title title-two">Billing Address</h2>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label class="label"> Country * </label>
								</div>
								<el-select class="input" style="padding: 15px 25px;" v-model="billingCountry" clearable
									filterable placeholder="Select">
									<el-option v-for="item in optionsContry" :key="item.alpha2" :label="item.alpha2"
										:value="item.alpha2">
										<div style="display: flex;align-items: center;">
											<img style="width: 30px;height: 20px;margin-right: 5px;" :src="item.logo"
												alt="">
											<p>{{ item.name }} (+{{ item.phoneCode }})</p>
										</div>
									</el-option>
								</el-select>
							</div>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label for="text" class="label"> Zip Code * </label>
								</div>
								<input type="text" class="input" name="billingZipCode" v-model="billingZipCode"
									maxlength="10">
							</div>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label for="text" class="label"> Address Line 1 * </label>
									<p class="description">50 characters max</p>
								</div>
								<input type="text" class="input" name="billingAddress1" v-model="billingAddress"
									maxlength="50">
							</div>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label for="text" class="label"> Address Line 2 * </label>
									<p class="description">50 characters max</p>
								</div>
								<input type="text" class="input" name="billingAddress2" v-model="billingAddressSecond"
									maxlength="50">
							</div>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label for="text" class="label"> City * </label>
								</div>
								<input type="text" class="input" name="billingCity" v-model="billingCity"
									maxlength="20">
							</div>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label for="text" class="label"> State * </label>
								</div>
								<input type="text" class="input" name="billingState" v-model="billingState"
									maxlength="20">
							</div>
							<div class="block-buttons">
								<div class="block-button">
									<a @click="backFirst" class="default-button border">Back</a>
								</div>
								<div class="block-button stepTwoButton">
									<div @click="ConfirePerson" type="submit" class="default-button">Submit</div>
								</div>
							</div>
						</div>

						<div class="step active" data-step="3" v-if="kycShow">
							<h2 class="title">KYC</h2>



							<div class="block-item default-input">
								<div class="default-input__top">
									<label class="label"> ID Type * </label>
								</div>
								<select class="default-select" name="idType" id="idtype" style="display: none;">
									<option value="">Select</option>
									<option value="passport" id="passport">Passport</option>
									<option value="nric" id="nric">NRIC</option>
									<option value="drivers_id" id="drivers_id">Driver's ID</option>
								</select>
								<div class="nice-select default-select" tabindex="0"><span class="current">Select</span>
									<ul class="list">
										<li data-value="" class="option selected">Select</li>
										<li data-value="passport" class="option">Passport</li>
										<li data-value="nric" class="option">NRIC</li>
										<li data-value="drivers_id" class="option">Driver's ID</li>
									</ul>
								</div>
							</div>



							<!-- 					       <div id="errorShowIdNumber"><span id='errorIdNumber' style="color:red; padding-bottom: 10px; text-align:center; display:block;"></span></div> 
 -->
							<div class="block-item default-input">
								<div class="default-input__top">
									<label for="text" class="label"> ID Number ( 5 to 20
										characters ) * </label>
								</div>
								<input type="text" class="input" name="idNumber" pattern=".{5,20}" id="idnumber"
									onchange="lengthValidation();" onkeypress="return onlyAlphanumerics(event)"
									value="">
							</div>
							<div class="block-item default-input">
								<div class="default-input__top">
									<label class="label"> ID Expiry Date </label>
								</div>
								<input type="text" class="input input-calendar_expiry" name="idExpiry" id="idExpiry"
									value="" readonly="">
							</div>
							<div class="block-item upload default-input" id="uploadDocumentDiv">
								<div class="default-input__top">
									<label for="text" class="label"> Upload Document
										(skippable) </label>
								</div>
								<input type="file" id="uploadInput" multiple="" accept="image/*">
								<label for="uploadInput" class="upload-block"> <span class="icon"><img
											:src="require('@/assets/img/dashboardPages/upload.svg')" alt=""></span>
									<p class="text">
										Click to upload <span class="gold">or drag and drop</span>
									</p>
									<p class="description">Maximum file size 50 MB</p>
								</label>
								<div class="upload-gallery"></div>
							</div>
							<div class="block-buttons">
								<div class="block-button">
									<a href="#" data-step="2" class="default-button border">Back</a>
								</div>
								<div class="block-button">
									<button type="submit" class="default-button"
										onclick="stepThreeValidation(event);">Submit
									</button>
								</div>
							</div>
						</div>
					</form>

					<div class="bottom">
						<p class="text" id="dateYear">© 2024 Bankpay. All rights reserved</p>
						<!-- <a href="#" class="link">Faq</a> -->
					</div>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
import {
	getNational,
	addUserInfo
} from "@/api/login";
import { getUserInfo } from '@/api/user'
import { getBankUserinfo } from '@/api/bankAccount'
export default {
	name: 'RegistrationComp',
	components: {

	},
	computed: {
		// currentPath: {
		//   get() {
		//     console.log("this.$route.path: " + this.$route.path)
		//     return this.$route.path; // 获取当前路由路径
		//   }
		// }
	},
	watch: {
		// '$route': { // $route可以用引号，也可以不用引号  监听的对象
		//     handler(to, from) {
		//         this.currentPath = to.path
		//         const pathList = [
		//             '/transactions',
		//             '/Setting'
		//         ]
		//         // console.log("watch router: ", this.currentPath)
		//         if (pathList.indexOf(this.currentPath) > -1) {
		//             this.getUserBankBalance()
		//         }
		//     },
		//     deep: true, // 深度观察监听 设置为 true
		//     immediate: true, // 第一次初始化渲染就可以监听到
		// }
	},
	created() { },
	mounted() {
		this.userInfo = JSON.parse(JSON.stringify(this.userInfo))
		this.getNationalData()
		this.initData()
	},
	computed: {
		userInfo() {
			return this.$store.state.userInfo
		}
	},
	data() {
		return {
			kycShow: false,
			PersonalDetail: true,
			addressShow: false,
			isChecked: false,
			options: [{
				value: 1,
				label: 'Mr',
			},
			{
				value: 2,
				label: 'Miss',
			},
			{
				value: 3,
				label: 'Mrs',
			},
			],
			optionsGender: [{
				value: 1,
				label: 'M',
			},
			{
				value: 2,
				label: 'F',
			},
			{
				value: 3,
				label: 'O',
			},
			],
			idTypeList: [
				{
					value: 0,
					label: 'passport',
				},
			],
			idNumber: '',
			optionsContry: [],
			title: '',
			phoneCountryCode: '',
			phone: '',
			nationality: '',
			middleName: '',
			lastName: '',
			idType: '',
			gender: '',
			firstName: '',
			deliveryZipCode: '',
			deliveryStreet: '',
			deliveryState: '',
			deliveryCountry: '',
			deliveryCity: '',
			deliveryAddressSecond: '',
			deliveryAddress: '',
			dateOfBirth: '',
			billingZipCode: '',
			billingStreet: '',
			billingState: '',
			billingCountry: '',
			billingCity: '',
			billingAddressSecond: '',
			billingAddress: '',
			emailAddress: '',
			countryCodeOptions:[]
		}
	},
	methods: {
		async getNationalData() {
			let res = await getNational();
			this.optionsContry = res.data;
			this.countryCodeOptions = []
			res.data.forEach(item=>{
				if(item.phoneCode !== "1" && item.phoneCode !== '86' ){
					this.countryCodeOptions.push(item)
				}
			})
			// this.changePhoneCountry(this.ruleForm.nationalId)
			// this.getphoneCountryLogo(val)
		},
		changeBox() {
			if (this.isChecked) {
				this.billingZipCode = this.deliveryZipCode,
					this.billingState = this.deliveryState,
					this.billingCountry = this.deliveryCountry,
					this.billingCity = this.deliveryCity,
					this.billingAddressSecond = this.deliveryAddressSecond,
					this.billingAddress = this.deliveryAddress
			}
		},
		initData() {
			this.$pu.showLoading()
			// Promise.all().then(results=>{

			// })
			getUserInfo().then(res => {
				this.$pu.hideLoading()
				if (res.code === 200) {
					this.isEnable2FA = res.data.hasGoogleAuth
					this.emailAddress = res.data.email
				} else {
					this.$message.error(res.msg)
				}
			}).catch(error => {
				this.$pu.hideLoading()
				this.$message.error(error)
			})
			getBankUserinfo().then(res => {
				this.$pu.hideLoading()
				if (res.code === 200) {
					this.fetchBankUserInfoCb(res.data)
				} else {
					this.$message.error(res.msg)
				}
			}).catch(error => {
				this.$pu.hideLoading()
				this.$message.error(error)
			})
		},
		backFirst() {
			this.PersonalDetail = true,
				this.addressShow = false
		},
		fetchBankUserInfoCb(data) {
			this.idNumber = data.idNumber
			this.title = data.title
			this.phoneCountryCode = data.phoneCountryCode
			this.phone = data.phone
			this.nationality = data.nationality
			this.middleName = data.middleName
			this.lastName = data.lastName
			this.idType = data.idType
			this.gender = data.gender
			this.firstName = data.firstName
			this.deliveryZipCode = data.deliveryZipCode
			this.deliveryStreet = data.deliveryStreet
			this.deliveryState = data.deliveryState
			this.deliveryCountry = data.deliveryCountry
			this.deliveryCity = data.deliveryCity
			this.deliveryAddressSecond = data.deliveryAddressSecond
			this.deliveryAddress = data.deliveryAddress
			this.dateOfBirth = data.dateOfBirth
			this.billingZipCode = data.billingZipCode
			this.billingStreet = data.billingStreet
			this.billingState = data.billingState
			this.billingCountry = data.billingCountry
			this.billingCity = data.billingCity
			this.billingAddressSecond = data.billingAddressSecond
			this.billingAddress = data.billingAddress
			this.emailAddress = data.emailAddress
		},
		addressShowNext() {
			if (!this.title) {
				this.$message.error('PLEASE SELSECT TITLE FIRST!')
				return
			}
			if (!this.firstName) {
				this.$message.error('PLEASE ENTER FIRST NAME FIRST!')
				return
			}
			if (!this.lastName) {
				this.$message.error('PLEASE ENTER LAST NAME FIRST!')
				return
			}
			if (!this.gender) {
				this.$message.error('PLEASE SELSECT GENDER FIRST!')
				return
			}
			if (!this.dateOfBirth) {
				this.$message.error('PLEASE SELSECT DATEOfBIRTH FIRST!')
				return
			}
			if (!this.idType) {
				this.$message.error('PLEASE SELSECT idType FIRST!')
				return
			}
			if (!this.idNumber) {
				this.$message.error('PLEASE ENTER idNumber FIRST!')
				return
			}
			if (!this.phoneCountryCode) {
				this.$message.error('PLEASE SELSECT PHONECOUNTRYCODE FIRST!')
				return
			}
			if (!this.phone) {
				this.$message.error('PLEASE ENTER Phone Number FIRST!')
				return
			}
			if (!this.nationality) {
				this.$message.error('PLEASE SELSECT Nationality FIRST!')
				return
			}
			this.PersonalDetail = false,
				this.addressShow = true
		},
		ConfirePerson() {
			if (!this.deliveryCountry) {
				this.$message.error('PLEASE SELSECT Country FIRST!')
				return
			}
			if (!this.deliveryZipCode) {
				this.$message.error('PLEASE ENTER Zip Code FIRST!')
				return
			}
			if (!this.deliveryAddress) {
				this.$message.error('PLEASE ENTER Address Line 1 FIRST!')
				return
			}
			if (!this.deliveryAddressSecond) {
				this.$message.error('PLEASE ENTER Address Line 2 FIRST!')
				return
			}
			if (!this.deliveryCity) {
				this.$message.error('PLEASE ENTER City FIRST!')
				return
			}
			if (!this.deliveryState) {
				this.$message.error('PLEASE ENTER State FIRST!')
				return
			}
			if (!this.billingCountry) {
				this.$message.error('PLEASE SELSECT Country FIRST!')
				return
			}
			if (!this.billingZipCode) {
				this.$message.error('PLEASE ENTER Zip Code FIRST!')
				return
			}
			if (!this.billingAddress) {
				this.$message.error('PLEASE ENTER Address Line 1 FIRST!')
				return
			}
			if (!this.billingAddressSecond) {
				this.$message.error('PLEASE ENTER Address Line 2 FIRST!')
				return
			}
			if (!this.billingCity) {
				this.$message.error('PLEASE ENTER City FIRST!')
				return
			}
			if (!this.billingState) {
				this.$message.error('PLEASE ENTER State FIRST!')
				return
			}
			let data = {
				title: this.title,
				firstName: this.firstName,
				middleName: this.middleName,
				phoneCountryCode: this.phoneCountryCode,
				phone: this.phone,
				nationality: this.nationality,
				lastName: this.lastName,
				gender: this.gender,
				deliveryZipCode: this.deliveryZipCode,
				deliveryStreet: this.deliveryStreet,
				deliveryState: this.deliveryState,
				deliveryCountry: this.deliveryCountry,
				deliveryCity: this.deliveryCity,
				deliveryAddressSecond: this.deliveryAddressSecond,
				deliveryAddress: this.deliveryAddress,
				dateOfBirth: this.dateOfBirth,
				billingZipCode: this.billingZipCode,
				billingState: this.billingState,
				billingCountry: this.billingCountry,
				billingCity: this.billingCity,
				billingAddressSecond: this.billingAddressSecond,
				billingAddress: this.billingAddress,
				email: this.emailAddress,
				idNumber: this.idNumber,
				idType: this.idType
			}
			this.$pu.showLoading()
			addUserInfo(data).then(res => {
				this.$pu.hideLoading()
				if (res.code == 200) {
					this.$message.success(res.msg)
					this.$router.push('/accountDashboard');
				} else {
					this.$message.error(res.msg)
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/libs.css';
@import '@/assets/css/dashboard.css';
@import '@/assets/css/custom.css';
</style>
<style lang="scss" scoped>
:deep(.default-input input) {
	border-radius: 15px;
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: -0.5px;
	color: #fff;
	background: transparent;
	width: 100%;
}

:deep(.el-select__placeholder) {
	color: #fff !important;
}

:deep(.el-select__wrapper) {
	color: #fff !important;
	padding: 0;
	background: transparent !important;
	box-shadow: none !important;
}

:deep(.el-input__wrapper) {
	color: #fff !important;
	padding: 0;
	background: transparent !important;
	box-shadow: none !important;
}
</style>