<template>
	<MainContentComp headerTitle="Cards">
		<section class="cards block" id="cards-block">

			<div class="messageBlock" data-message="activeCard">
				<span class="icon">
					<img :src="require('@/assets/img/cards/success.svg')" alt="">
				</span>
				<p class="text">Your Card has been activated successfully</p>
			</div>
			<div class="messageBlock error" data-message="blockCardTemporary">
				<span class="icon">
					<img :src="require('@/assets/img/cards/lock.svg')" alt="">
				</span>
				<p class="text">Your Card has been blocked temporarily!</p>
			</div>
			<div class="messageBlock error" data-message="blockCardPermanent">
				<span class="icon">
					<img :src="require('@/assets/img/cards/lock.svg')" alt="">
				</span>
				<p class="text">Your Card has been blocked permanently!</p>
			</div>
			<div class="messageBlock" data-message="pinUpDate">
				<span class="icon">
					<img :src="require('@/assets/img/cards/success.svg')" alt="">
				</span>
				<p class="text">Your Card PIN has been reset!</p>
			</div>
			<div class="messageBlock" data-message="unblockCard">
				<span class="icon">
					<img :src="require('@/assets/img/cards/lock-open.svg')" alt="">
				</span>
				<p class="text">Your Card has been unblocked successfully</p>
			</div>
			<div class="wrapper">
				<h2 class="content__title">Manage Cards</h2>
				<div class="cards__content">
					<div class="card" v-for="(item, index) in bankList" :key="index">
						<div class="card__top">
							<div class="card__currency">
								<div class="card__chip">
									<img :src="require('@/assets/img/cards/chip.svg')" alt="">
								</div>
							</div>
							<div class="card-inactive">
								<span class="icon" id="status">
									<img :src="require('@/assets/img/cards/info.svg')" alt=""> {{ item.cardStatus ==
						"active" ? "Active" : item.cardStatus == "suspended" ? "Temporarily Suspended" :
						"Permanently Suspended" }}</span>
							</div>
							<div class="card__currency-settings">
								<div class="button" @click="isActiveSHow(item, index)">
									<span class="icon">
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
												stroke="#3246D3" stroke-width="1.25" stroke-miterlimit="10"
												stroke-linecap="round" stroke-linejoin="round"></path>
											<path
												d="M1.66699 10.7334V9.26669C1.66699 8.40003 2.37533 7.68336 3.25033 7.68336C4.75866 7.68336 5.37533 6.6167 4.61699 5.30836C4.18366 4.55836 4.44199 3.58336 5.20033 3.15003L6.64199 2.32503C7.30033 1.93336 8.15033 2.1667 8.54199 2.82503L8.63366 2.98336C9.38366 4.2917 10.617 4.2917 11.3753 2.98336L11.467 2.82503C11.8587 2.1667 12.7087 1.93336 13.367 2.32503L14.8087 3.15003C15.567 3.58336 15.8253 4.55836 15.392 5.30836C14.6337 6.6167 15.2503 7.68336 16.7587 7.68336C17.6253 7.68336 18.342 8.39169 18.342 9.26669V10.7334C18.342 11.6 17.6337 12.3167 16.7587 12.3167C15.2503 12.3167 14.6337 13.3834 15.392 14.6917C15.8253 15.45 15.567 16.4167 14.8087 16.85L13.367 17.675C12.7087 18.0667 11.8587 17.8334 11.467 17.175L11.3753 17.0167C10.6253 15.7084 9.39199 15.7084 8.63366 17.0167L8.54199 17.175C8.15033 17.8334 7.30033 18.0667 6.64199 17.675L5.20033 16.85C4.44199 16.4167 4.18366 15.4417 4.61699 14.6917C5.37533 13.3834 4.75866 12.3167 3.25033 12.3167C2.37533 12.3167 1.66699 11.6 1.66699 10.7334Z"
												stroke="#3246D3" stroke-width="1.25" stroke-miterlimit="10"
												stroke-linecap="round" stroke-linejoin="round"></path>
										</svg>
									</span>
									<span class="close">
										<svg width="12" height="12" viewBox="0 0 12 12" fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M1 11L11 1" stroke="#3246D3" stroke-width="1.5"
												stroke-linecap="round"></path>
											<path d="M11 11L1 1" stroke="#3246D3" stroke-width="1.5"
												stroke-linecap="round"></path>
										</svg>
									</span>
								</div>
								<div class="card__settings" v-if="item.show" style="display: block;">
									<ul>
										<li class="card__settings-item" v-if="item.cardStatus == 'freeze'">
											<a @click="ActivateButtom" class="button-message"
												data-message="activeCard">Activate</a>
										</li>
										<li class="card__settings-item"
											v-if="item.cardStatus == 'active' && item.type == 'physical'">
											<a @click="ActivateCardPin" class="button-message"
												data-message="activeCard">Set Password</a>
										</li>
										<li class="card__settings-item" v-if="item.cardStatus == 'suspended'">
											<a @click="handDetail(item, 'unblock')" class="button-message"
												data-message="activeCard">Unfreeze</a>
										</li>
										<li class="card__settings-item" v-if="item.cardStatus == 'active'">
											<a @click="handDetail(item, 'temporaryBlock')" class="button-message"
												data-message="activeCard">Freeze card - Temporary</a>
										</li>
										<li class="card__settings-item" v-if="item.cardStatus == 'active'">
											<a @click="handDetail(item, 'temporaryBlock')" class="button-message"
												data-message="activeCard">Freeze card - Permanent </a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<a id="hiddencontinuebtmActiveCard" class="button modal-openButton"
							data-modal="ActiveCardModal2FA" onclick="ActiveCard2FAshowModal()">
						</a>
						<div class="card__bottom">
							<div class="fbr" style="align-items: baseline;display: flex;flex-direction: row;justify-content: space-between" v-if="item.type == 'virtual'">
								<p class="card-description" id="type">{{ item.type }}</p>
								<div>
									<span style="margin-left: 8px;">{{ item.createAt }}</span>
									<span style="margin-left: 8px;">{{ item.ccv }}</span>
								</div>
							</div>

							<p class="card-description" id="type" v-else>{{ item.type }}</p>
							<div class="card__number" v-if="item.type != 'virtual'">
								<span>****</span>
								<span>****</span>
								<span>****</span>
								<span class="card__number-show" id="lastdigit">{{ item.last4 }}</span>
							</div>
							<div class="card__number" v-else>
								{{ item.frontCardNo }}
							</div>
						</div>
					</div>

				</div>
			</div>
			<div v-if="openGoogleValid">
				<div class="modal cryptoAccountModal active" data-modal="ActiveCardModal2FA" v-if="isCode">
					<div class="modal-block">
						<div class="modal-top">
							<div class="tabs">
								<h1 class="modal-title">
									<!-- <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
				                                    <path class="svg__gray" fill-rule="evenodd" clip-rule="evenodd" d="M18.3545 8.70021C16.202 8.70021 14.457 10.4452 14.457 12.5977C14.457 13.0672 14.0764 13.4478 13.6069 13.4478C13.1374 13.4478 12.7568 13.0672 12.7568 12.5977C12.7568 9.50618 15.263 7 18.3545 7C21.4461 7 23.9522 9.50618 23.9522 12.5977C23.9522 13.0672 23.5716 13.4478 23.1021 13.4478C22.6326 13.4478 22.252 13.0672 22.252 12.5977C22.252 10.4452 20.5071 8.70021 18.3545 8.70021Z" fill="#FFA720"/>
				                                    <path class="svg__black" d="M9.26758 15.8777C9.4067 14.4296 9.47626 13.7056 9.80303 13.1583C10.0908 12.6763 10.5153 12.2907 11.0226 12.0503C11.5986 11.7773 12.326 11.7773 13.7807 11.7773H22.9275C24.3822 11.7773 25.1096 11.7773 25.6856 12.0503C26.1929 12.2907 26.6174 12.6763 26.9052 13.1583C27.2319 13.7056 27.3015 14.4296 27.4406 15.8777L28.4586 26.4734C28.624 28.1954 28.7067 29.0564 28.4222 29.7216C28.1722 30.306 27.7331 30.7893 27.1753 31.094C26.5404 31.4409 25.6754 31.4409 23.9455 31.4409H12.7627C11.0328 31.4409 10.1678 31.4409 9.53288 31.094C8.97511 30.7893 8.53597 30.306 8.28601 29.7216C8.00147 29.0564 8.08419 28.1954 8.24962 26.4734L9.26758 15.8777Z" fill="#3246D3"/>
				                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3542 19.0986C19.9654 19.0986 21.2715 17.7925 21.2715 16.1814C21.2715 15.7119 21.6521 15.3313 22.1216 15.3313C22.5911 15.3313 22.9717 15.7119 22.9717 16.1814C22.9717 18.7315 20.9044 20.7988 18.3542 20.7988C15.8041 20.7988 13.7368 18.7315 13.7368 16.1814C13.7368 15.7119 14.1174 15.3313 14.5869 15.3313C15.0564 15.3313 15.437 15.7119 15.437 16.1814C15.437 17.7925 16.7431 19.0986 18.3542 19.0986Z" fill="white"/>
				                                    <circle class="svg__gray" cx="29" cy="29" r="7" fill="#FFA720" stroke="#EFF6FF" stroke-width="2"/>
				                                    <path d="M26.6992 29H31.3006" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
				                                    <path d="M29 26.6992L29 31.3006" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
				                                </svg> -->

									Authentication
								</h1>

							</div>
							<div class="modal-close buttonClose" @click="isCode = false">
								<svg width="12" height="12" viewBox="0 0 12 12" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path d="M1 11L11 1" stroke="#3246D3" stroke-width="1.5" stroke-linecap="round">
									</path>
									<path d="M11 11L1 1" stroke="#3246D3" stroke-width="1.5" stroke-linecap="round">
									</path>
								</svg>
							</div>
						</div>
						<div class="cryptoAccountModal-main active">
							<div class="cryptoAccountModal-top">
								<p style="font-size:15px;"><strong>Please enter the 2FA code from your virtual 2FA
										device</strong></p>
								<!-- <input type="text"> -->
								<!-- <p>0Ã‚Â£</p> -->

								<div class="info">
									<!-- <div class="change">
				                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				                                        <path d="M8.38608 15.6146V5.38542C8.38608 4.62027 7.7658 4 7.00066 4C6.23551 4 5.61523 4.62027 5.61523 5.38542V15.6146C5.61523 16.3797 6.23551 17 7.00066 17C7.7658 17 8.38608 16.3797 8.38608 15.6146Z" fill="#1A1B26"/>
				                                        <path d="M15.6148 7.885L15.6148 18.115C15.6148 18.8799 16.2349 19.5 16.9998 19.5C17.7647 19.5 18.3848 18.8799 18.3848 18.115L18.3848 7.885C18.3848 7.12009 17.7647 6.5 16.9998 6.5C16.2349 6.5 15.6148 7.12009 15.6148 7.885Z" fill="#1A1B26"/>
				                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.41421 13.5C2.52331 13.5 2.07714 14.5771 2.70711 15.2071L6.29289 18.7929C6.68342 19.1834 7.31658 19.1834 7.70711 18.7929L11.2929 15.2071C11.9229 14.5771 11.4767 13.5 10.5858 13.5H3.41421Z" fill="#1A1B26"/>
				                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5858 10C21.4767 10 21.9229 8.92286 21.2929 8.29289L17.7071 4.70711C17.3166 4.31658 16.6834 4.31658 16.2929 4.70711L12.7071 8.29289C12.0771 8.92286 12.5233 10 13.4142 10L20.5858 10Z" fill="#1A1B26"/>
				                                    </svg>
				                                </div> -->
									<!-- <div class="cur"><img src="img/cryptoAccount/btc.svg" alt=""></div> -->
								</div>
							</div>
							<div class="cryptoAccountModal-bottom">
								<div class="default-input item">
									<input type="text" v-model="code2f" placeholder="Enter the six digit code here"
										autocomplete="off" maxlength="6" required="">
								</div>
								<button type="button" @click="SubmitCode" class="button">Submit</button>
								<div class="info">
									<!-- <p class="name">BTC Balance</p>
				                                <p class="bet">0.000003284 BTC Ã¢Â‰Âˆ Ã‚Â£0.41</p> -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal cryptoAccountModal active" data-modal="Set Password" v-if="cardPin">
				<div class="modal-block">
					<div class="modal-top">
						<div class="tabs">
							<h1 class="modal-title">
								<!-- <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			                                    <path class="svg__gray" fill-rule="evenodd" clip-rule="evenodd" d="M18.3545 8.70021C16.202 8.70021 14.457 10.4452 14.457 12.5977C14.457 13.0672 14.0764 13.4478 13.6069 13.4478C13.1374 13.4478 12.7568 13.0672 12.7568 12.5977C12.7568 9.50618 15.263 7 18.3545 7C21.4461 7 23.9522 9.50618 23.9522 12.5977C23.9522 13.0672 23.5716 13.4478 23.1021 13.4478C22.6326 13.4478 22.252 13.0672 22.252 12.5977C22.252 10.4452 20.5071 8.70021 18.3545 8.70021Z" fill="#FFA720"/>
			                                    <path class="svg__black" d="M9.26758 15.8777C9.4067 14.4296 9.47626 13.7056 9.80303 13.1583C10.0908 12.6763 10.5153 12.2907 11.0226 12.0503C11.5986 11.7773 12.326 11.7773 13.7807 11.7773H22.9275C24.3822 11.7773 25.1096 11.7773 25.6856 12.0503C26.1929 12.2907 26.6174 12.6763 26.9052 13.1583C27.2319 13.7056 27.3015 14.4296 27.4406 15.8777L28.4586 26.4734C28.624 28.1954 28.7067 29.0564 28.4222 29.7216C28.1722 30.306 27.7331 30.7893 27.1753 31.094C26.5404 31.4409 25.6754 31.4409 23.9455 31.4409H12.7627C11.0328 31.4409 10.1678 31.4409 9.53288 31.094C8.97511 30.7893 8.53597 30.306 8.28601 29.7216C8.00147 29.0564 8.08419 28.1954 8.24962 26.4734L9.26758 15.8777Z" fill="#3246D3"/>
			                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3542 19.0986C19.9654 19.0986 21.2715 17.7925 21.2715 16.1814C21.2715 15.7119 21.6521 15.3313 22.1216 15.3313C22.5911 15.3313 22.9717 15.7119 22.9717 16.1814C22.9717 18.7315 20.9044 20.7988 18.3542 20.7988C15.8041 20.7988 13.7368 18.7315 13.7368 16.1814C13.7368 15.7119 14.1174 15.3313 14.5869 15.3313C15.0564 15.3313 15.437 15.7119 15.437 16.1814C15.437 17.7925 16.7431 19.0986 18.3542 19.0986Z" fill="white"/>
			                                    <circle class="svg__gray" cx="29" cy="29" r="7" fill="#FFA720" stroke="#EFF6FF" stroke-width="2"/>
			                                    <path d="M26.6992 29H31.3006" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
			                                    <path d="M29 26.6992L29 31.3006" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
			                                </svg> -->

								Set Password
							</h1>

						</div>
						<div class="modal-close buttonClose" @click="cardPin = false">
							<svg width="12" height="12" viewBox="0 0 12 12" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path d="M1 11L11 1" stroke="#3246D3" stroke-width="1.5" stroke-linecap="round"></path>
								<path d="M11 11L1 1" stroke="#3246D3" stroke-width="1.5" stroke-linecap="round"></path>
							</svg>
						</div>
					</div>
					<div class="cryptoAccountModal-main active">
						<div class="cryptoAccountModal-bottom">
							<p class="passTitle">Set Password</p>
							<div class="default-input item">
								<input type="text" v-model="pin" placeholder="Please enter new password"
									autocomplete="off" maxlength="6" required="">
							</div>
							<p class="passTitle">Please re-confirm password</p>
							<div class="default-input item">
								<input type="text" v-model="pin1" placeholder="Please re-confirm password"
									autocomplete="off" maxlength="6" required="">
							</div>
							<button type="button" @click="confirePin()" class="button">Submit</button>
							<div class="info">
								<!-- <p class="name">BTC Balance</p>
			                                <p class="bet">0.000003284 BTC Ã¢Â‰Âˆ Ã‚Â£0.41</p> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</MainContentComp>
</template>

<script>
import {
	userCards,
	activeCard,
	setPin,
	opCard
} from '@/api/card'
import MainContentComp from '@/components/section/mainComp.vue'
import { getUserInfo } from '@/api/user'
import { openValid, openGoogleAuth, getGoogleAuth } from '@/api/setting'
export default {
	name: 'FeeTable',
	components: {
		MainContentComp
	},
	props: {
		msg: String
	},
	data() {
		return {
			bankList: [],
			isActive: '',
			isCode: false,
			show: false,
			code2f: '',
			openGoogleValid: '',
			cardId: '',
			cardPin: false,
			pin: '',
			pin1: ''
		}
	},
	mounted() {
		this.getUserCard()
		this.initData()
	},
	methods: {
		getUserCard() {
			this.$pu.showLoading()
			userCards().then(res => {
				this.$pu.hideLoading()
				this.bankList = res.data.map((item) => {
					item.show = false;
					item.visible = false;
					return item;
				});
				this.bankList = res.data
			})
		},
		initData() {
			this.$pu.showLoading()
			// Promise.all().then(results=>{

			// })
			getUserInfo().then(res => {
				this.$pu.hideLoading()
				if (res.code === 200) {
					this.openGoogleValid = res.data.openGoogleValid
				} else {
					this.$message.error(res.msg)
				}
			}).catch(error => {
				this.$pu.hideLoading()
				this.$message.error(error)
			})
		},
		isActiveSHow(item, index) {
			let coinListPrxy = JSON.parse(JSON.stringify(item))
			this.cardId = coinListPrxy.id
			this.bankList.forEach(res => {
				if (res.id == item.id) {
					res.show = !res.show;
				} else {
					res.show = false;
				}
			})
			item.visible = true;
		},
		ActivateButtom() {
			this.code2f = ''
			this.isCode = true
		},
		ActivateCardPin() {
			this.cardPin = true
		},
		confirePin() {
			if (this.pin !== this.pin1) {
				this.$message.error('Passwords does not match!')
				return
			}
			this.$pu.showLoading()
			let data = {
				pin: this.pin,
				pin1: this.pin1,
				id: this.cardId
			}
			// console.log("data: " + JSON.stringify(data))
			setPin(data).then(res => {
				this.$pu.hideLoading()
				if (res.code == 200) {
					this.$message.success(res.msg);
					this.getUserCard()
					this.cardPin = false;
					this.pin = ''
					this.pin1 = ''
				} else {
					this.$message.error(this.translateTitle(res.msg));
				}
			})
		},
		handDetail(item, val) {
			let params = {
				id: item.id,
				opType: val,
			};
			this.$pu.showLoading()
			opCard(params).then((res) => {
				this.$pu.hideLoading()
				if (res.code == 200) {
					item.cardStatus = val;
					this.$message.success(res.msg);
					this.getUserCard()
				} else {
					this.$message.error(res.msg);
				}
			})
		},
		SubmitCode() {
			this.$pu.showLoading()
			const params = {
				securityCode: this.code2f
			}
			openGoogleAuth(params).then(res => {
				this.$pu.hideLoading()
				if (res.code === 200) {
					this.$message.success('2FA enabled successfully!')
					let _this = this
					setTimeout(() => {
						activeCard(this.cardId).then(res => {
							if (res.code === 200) {
								this.isCode = false
								this.getUserCard()
							} else {
								this.$message.error(res.msg)
							}
						}).catch(error => {
							this.$message.error(error)
						})
					}, 500);
				} else {
					this.$message.error(res.msg)
				}
			}).catch(error => {
				this.$pu.hideLoading()
				this.$message.error(error)
			})
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/main.css';

.passTitle {
	font-size: 14px;
	font-weight: 400;
	color: rgba(49, 54, 62, 0.6);
	line-height: 26px;
}
</style>
